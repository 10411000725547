@keyframes airplane-enter {
  from {
    
  }

  to {
    left: calc(50% - min(2.5vh, 2.5vw));
    top: calc(50% - min(5vh, 5vw) + min(10vh, 10vw));
    height: calc(min(5vh, 5vw));
    width: calc(min(5vh, 5vw));
  }
}

@keyframes airplane-spin {
  from {
    transform: rotate(450deg) translate(calc(min(10vh, 10vw)))
  }
  to {
    transform: rotate(90deg) translate(calc(min(10vh, 10vw)));
  }
}

@keyframes airplane-exit {
  from {

  }
  to {
    left: 130%;
    top: calc(50% - min(5vh, 5vw) + min(10vh, 10vw));
    /* top: calc(50% - min(20vh, 20vh)); */
    height: calc(min(20vh, 20vh));;
    width: calc(min(20vh, 20vh));;
  }
}

@keyframes airplane-spin-exit {
  from {
  transform: rotate(90deg) translate(calc(-1 * min(10vh, 10vw)));  }

  /* 30% {
    top: calc(50% - min(5vh, 5vh));
    transform: rotate(180deg) translate(0 - calc(min(10vh, 10vw)));
  } */

  70% {
    top: 90%;
    transform: rotate(540deg) translate(calc(-1 * min(5vh, 5vw)));
  }

  to {
    top: 110%;
    transform: rotate(540deg) translate(calc(-1 * min(5vh, 5vw)));
  }
}

.svg-container-initial {
  position: absolute;
  left: -30%;
  top: calc(50% - min(5vh, 5vw) + min(10vh, 10vw));
  /* top: calc(50% - min(20vh, 20vh)); */
  transform: rotate(90deg);
  transform-origin: center center;
  height: calc(min(20vh, 20vh));
  width: calc(min(20vh, 20vh));
  animation-name: airplane-enter;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

.svg-container-spin {
  position: absolute;
  left: calc(50% - min(2.5vh, 2.5vw));
      
  top: calc(50% - min(5vh, 5vh));
  transform: rotate(90deg) translate(calc(min(10vh, 10vw)));
  transform-origin: center center;
  height: min(5vh, 5vh);
  width: min(5vh, 5vh);
  animation-name: airplane-spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-timing-function: cubic-bezier();
}

.svg-container-exit {
  position: fixed;
  left: 50%;
  top: calc(50% - min(5vh, 5vw) + min(10vh, 10vw));  
  transform: rotate(90deg);
  height: min(5vh, 5vh);
  width: min(5vh, 5vh);
  animation-name: airplane-exit;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

.svg-container-spin-exit {
  position: fixed;
  left: 50%;
  top: calc(50% + min(15vh, 15vw));
  transform: rotate(90deg) translate(calc(-1 * min(10vh, 10vw)));
  transform-origin: center center;
  height: min(5vh, 5vh);
  width: min(5vh, 5vh);
  animation-name: airplane-spin-exit;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}